import React from "react";

const Terms = ({ getTranslatedText }) => {
  return (
    <div>
      <section className="terms">
        <div className="terms__header"></div>
        <div className="terms__container">
          <div className="terms__title-wrapper">
            <h1 className="terms__title">
              {getTranslatedText("Terms HeadingText")}
            </h1>
          </div>
          <p className="terms__text">
            {getTranslatedText("Terms HeadingTermsText1")}
            <a href="https://creditper.pk/">www.creditper.pk</a>
            {getTranslatedText("Terms HeadingTermsText2")}
          </p>
          <p className="terms__text">
            {getTranslatedText("Terms HeadingTermsText3")}
          </p>
          <h3>{getTranslatedText("Terms HeadingGeneral")}</h3>
          <p className="terms__text">
            {getTranslatedText("Terms HeadingGeneralText1")}
          </p>
          <h3>{getTranslatedText("Terms HeadingEligibility")}</h3>
          <p className="terms__text">
            {getTranslatedText("Terms HeadingEligibilityText1")}
          </p>
          <p className="terms__text">
            {getTranslatedText("Terms HeadingEligibilityText2")}
          </p>
          <h3>{getTranslatedText("Terms HeadingAcceptance")}</h3>
          <p className="terms__text">
            {getTranslatedText("Terms HeadingAcceptanceText1")}
          </p>
          <h3>{getTranslatedText("Terms HeadingAccount")}</h3>
          <p className="terms__text">
            {getTranslatedText("Terms HeadingAccountText1")}
          </p>
          <p className="terms__text">
            {getTranslatedText("Terms HeadingAccountText2")}
          </p>
          <p className="terms__text">
            {getTranslatedText("Terms HeadingAccountText3")}
          </p>
          <h3>{getTranslatedText("Terms HeadingLicense")}</h3>
          <p className="terms__text">
            {getTranslatedText("Terms HeadingLicenseText1")}
          </p>
          <h3>{getTranslatedText("Terms HeadingMonitoring")}</h3>
          <p className="terms__text">
            {getTranslatedText("Terms HeadingMonitoringText1")}
          </p>
          <h3>{getTranslatedText("Terms HeadingAccesss")}</h3>
          <p className="terms__text">
            {getTranslatedText("Terms HeadingAccesssText1")}
          </p>
          <p className="terms__text">
            {getTranslatedText("Terms HeadingAccesssText2")}
          </p>
          <p className="terms__text">
            {getTranslatedText("Terms HeadingAccesssText3")}
          </p>
          <h3>{getTranslatedText("Terms HeadingIndemnity")}</h3>
          <p className="terms__text">
            {getTranslatedText("Terms HeadingIndemnityText1")}
          </p>
          <h3>{getTranslatedText("Terms HeadingAcknowledgements")}</h3>
          <p className="terms__text">
            {getTranslatedText("Terms HeadingAcknowledgementsText1")}
          </p>
          <h3>{getTranslatedText("Terms HeadingLaws")}</h3>
          <p className="terms__text">
            {getTranslatedText("Terms HeadingLawsText1")}
          </p>
          <h3>{getTranslatedText("Terms HeadingCurrency")}</h3>
          <p className="terms__text">
            {getTranslatedText("Terms HeadingCurrencyText1")}
          </p>
          <h3>{getTranslatedText("Terms HeadingGrievances")}</h3>
          <p className="terms__text">
            {getTranslatedText("Terms HeadingGrievancesText1")}
            <br />
            <a href="mailto:hello@creditper.pk">hello@creditper.pk</a>
          </p>
          <h3>{getTranslatedText("Terms HeadingGrievancesOfficer")}</h3>
          <p className="terms__text">
            {getTranslatedText("Terms HeadingGrievancesOfficerText1")}
          </p>
          <h3>{getTranslatedText("Terms HeadingRefunds")}</h3>
          <p className="terms__text">
            {getTranslatedText("Terms HeadingRefundsText1")}
            <a href="mailto:hello@creditper.pk">hello@creditper.pk</a>
            {getTranslatedText("Terms HeadingRefundsText2")}
          </p>
        </div>
      </section>
    </div>
  );
};

export default Terms;
